const Service = ({ service }) => {
  return (
    <div className="col-lg-4">
      <div
        className={`service-box text-center px-4 py-5 position-relative mb-4 ${
          service.active ? " active " : ""
        }`}
      >
        <div className="service-box-content p-4">
          <div className="icon-mono service-icon avatar-md mx-auto mb-4">
            <i
              className={`${service.icon} display-6 text-${service.color}`}
            ></i>
          </div>
          <h4 className="mb-3 font-size-22">{service.title}</h4>
          <p className="text-muted mb-0">{service.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
