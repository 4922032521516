import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

// Components
import {
  Layout,
  Showcase,
  Services,
  Features,
  BuildDream,
  Testmonials,
  ContactUs,
  About,
} from "./components";

const App = () => {
  return (
    <Fragment>
      <Layout>
        <Routes>
          <Route
            path="/"
            index
            element={
              <>
                <Showcase />
                <Services />
                <Features />
                <BuildDream />
                <Testmonials />
                <ContactUs />
              </>
            }
          />
          <Route path="/services" element={<Services />} />
          <Route
            path="/testmonials"
            element={
              <>
                <Testmonials /> <BuildDream />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Features />} />
          <Route
            path="/contact-us"
            element={
              <>
                <ContactUs />
              </>
            }
          />
        </Routes>
      </Layout>
    </Fragment>
  );
};

export default App;
