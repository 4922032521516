import React from "react";

import data from "./data";
import Feature from "./Feature";

const Features = () => {
  return (
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Our Features</h2>
            <p class="text-muted">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium totam rem ab illo inventore.
            </p>
          </div>
        </div>

        {data && data.map((feature) => <Feature feature={feature} />)}
      </div>
    </section>
  );
};

export default Features;
