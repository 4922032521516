import React from "react";

const Image = () => {
  return (
    <div className="col-lg-6 col-sm-10 mx-auto ms-lg-auto me-lg-0 animate__animated animate__backInRight">
      <div className="mt-lg-0 mt-4">
        <img
          src="/assets/images/illustration927.png"
          alt=""
          className="img-xl-responsive img-fluid animate__animated animate__delay-2s animate__pulse animate__infinite animate__slower"
        />
      </div>
    </div>
  );
};

export default Image;
