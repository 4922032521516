import { Link } from "react-router-dom";

// Components
import NavLink from "./NavLink";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="navbar">
      <div className="container">
        <Link to="/" className="navbar-brand logo">
          <img
            src="/assets/images/tiigsi.png"
            alt="Tiigsi Solutions"
            className="logo-dark"
            height="40"
          />
          <img
            src="/assets/images/tiigsi.png"
            alt="Tiigsi Solutions"
            className="logo-light"
            height="40"
          />
        </Link>
        <button
          className="navbar-toggler "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list display-6" data-feather="menu"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ms-auto navbar-center" id="navbar-navlist">
            <NavLink title="home" to="/" />
            <NavLink title="services" to="/services" />
            <NavLink title="features" to="/features" />
            <NavLink title="testmonials" to="/testmonials" />
            <NavLink title="about" to="/about" />
            <Link
              to="/contact-us"
              class="btn btn-sm rounded-pill nav-btn ms-lg-3"
            >
              Contact Us
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
