import React from "react";
import Service from "./Service";
import data from "./data";

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-lg-7 text-center">
            <h2 className="fw-bold">Our Services</h2>
            <p className="text-muted">
              We provide timely business information solutions. Our capacities
              involve Software Development & hosting, Branding & Digital
              Marketing, integration security technology, business intelligence,
              strategic delivery capabilities and a host of other ICT related
              services.
            </p>
          </div>
        </div>

        <div className="row">
          {data && data.map((service) => <Service service={service} />)}
        </div>
      </div>
    </section>
  );
};

export default Services;
