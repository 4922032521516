const features = [
  {
    title: "COMMITMENT TO CLIENT NEEDS",
    description:
      "Understanding the needs of our clients and delivering results that position them ahead of the competition.",
    to: "/features",
    image: "/assets/images/features-1.jpg",
    isReverse: false,
  },
  {
    title: "CUSTOMER SERVICE",
    description:
      "A team of professionals dedicated to answering questions and addressing issues in a swift manner.",
    to: "/features",
    image: "/assets/images/features-2.jpg",
    isReverse: true,
  },
  {
    title: "CUTTING EDGE PRODUCTS",
    description:
      "Systems designed with a forward-thinking approach and proven methodologies.",
    to: "/features",
    image: "/assets/images/hero-4-bg.jpg",
    isReverse: false,
  },
  {
    title: "CUSTOMER SERVICE",
    description:
      "A team of professionals dedicated to answering questions and addressing issues in a swift manner.",
    to: "/features",
    image: "/assets/images/hero-7-bg.jpg",
    isReverse: true,
  },
];

export default features;
