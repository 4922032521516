const testmonials = [
  {
    description:
      "Waa Qoraal Tusaale Ah oo loogu talay, natus error sit voluptatem accusantium doloremque laudantium totam rem ab illo inventore.",
    name: "Mohamed Hassan",
    title: "Manager, Hargeisa",
    img: "https://avatars.githubusercontent.com/u/5058076?v=4",
  },
];

export default testmonials;
