import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="col-lg-5">
      <span className="badge badge-soft-primary mb-4 animate__animated animate__zoomIn">
        IT Solutions
      </span>
      <h1 className="font-weight-semibold mb-4 hero-2-title text-capitalize animate__animated animate__fadeInLeft">
        We provide timely business information solutions.
      </h1>
      <p className="mb-5 text-muted animate__animated animate__fadeInUp">
        Our capacities involve Software Development & hosting, Branding &
        Digital Marketing, integration security technology, business
        intelligence, strategic delivery capabilities and a host of other ICT
        related services.
      </p>
      <div>
        <Link
          to="/get-in-touch"
          className="btn btn-sm btn-primary rounded-pill px-2 animate__animated animate__zoomIn animcate__slow"
        >
          Get Start
          <i className="bi bi-chevron-right p-1"></i>
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
