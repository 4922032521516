import { Fragment } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Nav = ({ title, to, props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Fragment>
      <li className={"nav-item text-capitalize"}>
        <Link
          to={to}
          className={
            match
              ? "nav-link active border-bottom border-2 border-light-secondary"
              : " nav-link"
          }
        >
          {title}
        </Link>
      </li>
    </Fragment>
  );
};

export default Nav;
