const Testmonial = ({ testmonial }) => {
  return (
    <div class="col-lg-4">
      <div class="card mt-4 border-0 shadow">
        <div class="card-body p-4">
          <p class="text-muted">{testmonial.description}</p>
          <div class="d-flex align-items-center mt-4 pt-2">
            <img
              src={testmonial.img}
              class="rounded-circle avatar-sm me-3"
              alt={testmonial.name}
            />
            <div class="flex-body">
              <h5 class="font-size-17 mb-0">{testmonial.name}</h5>
              <p class="text-muted mb-0 font-size-14">{testmonial.title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testmonial;
