import React from "react";
import { Link } from "react-router-dom";

const Feature = ({ feature }) => {
  return !feature.isReverse ? (
    <div className="row align-items-center mb-5">
      <div className="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
        <h2 className="mb-4">{feature.title}</h2>
        <p className="text-muted mb-5">{feature.description}</p>
        <Link to={feature.to} className="btn btn-primary">
          Find out more <i className="icon-xs ms-2"></i>
        </Link>
      </div>
      <div className="col-md-6 ms-md-auto order-1 order-md-2">
        <div className="position-relative">
          <div className="ms-5 features-img">
            <img
              src={feature.image}
              alt={feature.title}
              className="img-fluid d-block mx-auto rounded shadow"
            />
          </div>
          <img
            src="/assets/images/dot-img.png"
            alt=""
            className="dot-img-left"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="row align-items-center section pb-0 mb-5">
      <div className="col-md-6">
        <div className="position-relative mb-md-0 mb-5">
          <div className="me-5 features-img">
            <img
              src={feature.image}
              alt={feature.title}
              className="img-fluid d-block mx-auto rounded shadow  mt-5"
            />
          </div>
          <img
            src="/assets/images/dot-img.png"
            alt=""
            className="dot-img-right"
          />
        </div>
      </div>
      <div className="col-md-5 ms-md-auto">
        <h2 className="mb-4">{feature.title}</h2>
        <p className="text-muted mb-5">{feature.description}</p>
        <Link to={feature.to} className="btn btn-primary">
          Find out more{" "}
          <i className="icon-xs ms-2" data-feather="arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default Feature;
