const services = [
  {
    title: "Software Development",
    icon: "bi bi-window-sidebar",
    isActive: false,
    color: "warning",
    description:
      "We convert your idea into solid, safe, and reliable custom software with our full-cycle software development services",
  },
  {
    title: "Branding & Digital Marketing",
    icon: "bi bi-window-dock",
    isActive: true,
    color: "primary",
    description:
      "At the heart of our services, we provide powerful branding solutions that bring together design and language, creating!",
  },
  {
    title: "Web Design & Development",
    icon: "bi bi-window-sidebar",
    color: "success",
    isActive: false,
    description:
      "Client-focused, customer-centric, creating website solutions that deliver tangible business results, Tiigsi's web developers helps brands the ever-changing digital landscape.",
  },
];

export default services;
