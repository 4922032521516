import React from "react";
import { Link } from "react-router-dom";

const BuildDream = () => {
  return (
    <section class="section bg-gradient-primary">
      <div
        class="bg-overlay-img"
        style={{ background: "url(/assets/images/demos.png)" }}
      ></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center">
              <h1 class="text-white mb-4">Build your dream website today</h1>
              <p class="text-white mb-5 font-size-16">
                Qoraan Tusaale Ah omnis iste natus error sit voluptatem
                accusantium doloremque laudantium totamrem aperiam eaque
                inventore veritatis quasi.
              </p>
              <Link to="/contact-us" class="btn btn-lg btn-light">
                Ask for Demonstration
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuildDream;
