import data from "./data";
import Testmonial from "./Testmonial";

const Testmonails = () => {
  return (
    <section class="section" id="blog">
      <div class="container">
        <div class="row justify-content-center mb-4">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Testmonails</h2>
            <p class="text-muted">
              Waa Qoraal Tusaale Ah oo loogu talay, natus error sit voluptatem
              accusantium doloremque laudantium totam rem ab illo inventore.
            </p>
          </div>
        </div>
        <div class="row">
          <Testmonial testmonial={data[0]} />
          <Testmonial testmonial={data[0]} />
          <Testmonial testmonial={data[0]} />
        </div>
      </div>
    </section>
  );
};

export default Testmonails;
