import React from "react";

const Form = () => {
  return (
    <form method="post" name="myForm" onsubmit="return validateForm()">
      <p id="error-msg"></p>
      <div id="simple-msg"></div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-4">
            <label for="name" className="text-muted form-label">
              Name
            </label>
            <input
              name="name"
              id="name"
              type="text"
              className="form-control"
              placeholder="Enter name*"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-4">
            <label for="email" className="text-muted form-label">
              Email
            </label>
            <input
              name="email"
              id="email"
              type="email"
              className="form-control"
              placeholder="Enter email*"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-4">
            <label for="subject" className="text-muted form-label">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder="Enter Subject.."
            />
          </div>

          <div className="mb-4 pb-2">
            <label for="comments" className="text-muted form-label">
              Message
            </label>
            <textarea
              name="comments"
              id="comments"
              rows="4"
              className="form-control"
              placeholder="Enter message..."
            ></textarea>
          </div>

          <button
            type="submit"
            id="submit"
            name="send"
            className="btn btn-primary"
          >
            Send Message
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
