import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ to, title }) => {
  return (
    <li>
      <Link to={to} className="footer-link">
        {title}
      </Link>
    </li>
  );
};

export default NavLink;
