import React from "react";
import Welcome from "./Welcome";
import Image from "./Image";

const Showcase = () => {
  return (
    <>
      {" "}
      <section
        className="hero-2 position-relative overflow-hidden"
        style={{ background: "url(/assets/images/hero-6-bg.png)" }}
        id="home"
      >
        <div className="container">
          <div className="row align-items-center">
            <Welcome />
            <Image />
          </div>
        </div>
      </section>
      <hr />
    </>
  );
};

export default Showcase;
