import React from "react";
import { Link } from "react-router-dom";
import classes from "./footer.module.css";
import NavLink from "./NavLink";

const Footer = () => {
  return (
    <footer
      className={"footer " + classes.bgFooter}
      style={{ backgroundImage: "url(/assets/images/footer-bg.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="mb-4 ">
              <Link to="/" className="bg-light py-4  px-4 rounded h-auto">
                <img
                  src="/assets/images/tiigsi.png"
                  alt=""
                  className="mb-1"
                  height="50"
                />
              </Link>
              <p className="text-white-50 my-4">
                Qoraal Tusaale Ah. eos et accusamus et iusto odio dignissimos
                ducimus qui blanditiis praesentium voluptatum deleniti.
              </p>
            </div>
          </div>
          {/* <!-- end col --> */}

          <div className="col-lg-7 ms-lg-auto">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="mt-4 mt-lg-0">
                  <h4 className="text-white font-size-18 mb-3">Pages</h4>
                  <ul className="list-unstyled footer-sub-menu">
                    <NavLink title="Home" to="/" />
                    <NavLink title="Services" to="/services" />
                    <NavLink title="Portfolio" to="/portfolio" />
                    <NavLink title="Team" to="/team" />
                    <NavLink title="Testmonials" to="/testmonials" />
                    <NavLink title="About us" to="/about-us" />
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="mt-4 mt-lg-0">
                  <h4 className="text-white font-size-18 mb-3">Services</h4>
                  <ul className="list-unstyled footer-sub-menu">
                    <NavLink title="Web Design" to="/services" />
                    <NavLink title="Web Development" to="/services" />
                    <NavLink title="Web Apps" to="/services" />
                    <NavLink title="Desktop Apps" to="/services" />
                    <NavLink title="Mobile Apps" to="/services" />
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="mt-4 mt-lg-0">
                  <h4 className="text-white font-size-18 mb-3">Information</h4>
                  <ul className="list-unstyled footer-sub-menu">
                    <NavLink title="Developers" to="/team" />
                    <NavLink title="Features" to="/features" />
                    <NavLink title="About Us" to="/about-us" />
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="mt-4 mt-lg-0">
                  <h4 className="text-white font-size-18 mb-3">Support</h4>
                  <ul className="list-unstyled footer-sub-menu">
                    <NavLink title="Get Start" to="/contac-us" />
                    <NavLink title="Contact" to="/contac-us" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-5">
              <p className="text-white-50 f-15 mb-0">
                {new Date().getFullYear()} © Tiigsi Solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
