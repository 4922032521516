import { Fragment } from "react";
import Footer from "../Footer";

// Compnoents
import Navbar from "../Navbar";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      <main className=" min-vh-100">{children}</main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
