import React from "react";
import Form from "./Form";

const ContactUs = () => {
  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="fw-bold mb-4">Get in Touch</h2>
            <p className="text-muted mb-5">
              Waa qoraal tusaale ah. rerum facilis est expedita distinctio
              temporecum soluta nobis est eligendi optio cumque nihil impedit
              quo minus maxime.
            </p>

            <div>
              <Form />
            </div>
          </div>

          <div className="col-lg-5 ms-lg-auto">
            <div className="mt-5 mt-lg-0">
              <img
                src="/assets/images/contact.png"
                alt=""
                className="img-fluid d-block"
              />
              <p className="text-muted mt-5 mb-3">
                <i
                  className="me-2 text-muted icon icon-xs"
                  data-feather="mail"
                ></i>
                Support@info.com
              </p>
              <p className="text-muted mb-3">
                <i
                  className="me-2 text-muted icon icon-xs"
                  data-feather="phone"
                ></i>{" "}
                +91 123 4556 789
              </p>
              <p className="text-muted mb-3">
                <i
                  className="me-2 text-muted icon icon-xs"
                  data-feather="map-pin"
                ></i>
                2976 Edwards Street Rocky Mount, NC 27804
              </p>
              <ul className="list-inline pt-4">
                <li className="list-inline-item me-3">
                  <a
                    href="https://facebook.com/tiigsi-solutions"
                    rel="noreferrer"
                    target="_blank"
                    className="social-icon icon-mono avatar-xs rounded-circle"
                  >
                    <i className=" bi bi-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item me-3">
                  <a
                    href="https://twitter.com/tiigsi-solutions"
                    rel="noreferrer"
                    target="_blank"
                    className="social-icon icon-mono avatar-xs rounded-circle"
                  >
                    <i className=" bi bi-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item me-3">
                  <a
                    href="https://instagram.com/tiigsi-solutions"
                    rel="noreferrer"
                    target="_blank"
                    className="social-icon icon-mono avatar-xs rounded-circle"
                  >
                    <i className=" bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
